import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, updateProduct } from "../redux/productsSlice";
import { IoCloseOutline } from "react-icons/io5";
import Switch from "react-switch";
import Select from "react-select";
import { FaFilePdf } from "react-icons/fa";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ImageSlider from "./ImageSlider";
import ImageLoader from "./ImageLoader";
import GeneTable from "./GeneTable";
import ProteinGeneTable from "./ProteinGeneTable";
import { PiTrash } from "react-icons/pi";
import { nanoid } from 'nanoid'
import { json, useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import AppConfig from "../AppConfig";
import stages from "../assets/data/stage";
import hosts from "../assets/data/host";
import tools from "../assets/data/tools";
import PDFViewer from "./PDFViewer";
import { useLocation } from 'react-router-dom';
import Footer from "./Footer";
import { BiDotsVerticalRounded } from "react-icons/bi";
// import { toastNotifyError, toastNotifySucess } from '../slices/toast';
import { toast } from 'sonner';
import ProductInfo from "./product/ProductInfo";
import ProductHost from "./product/ProductHost";
import ProductExperiments from "./product/ProductExperiments";
import CodonOptimization from "./product/CodonOptimization";
import ExperimentDetail from "./product/ExperimentDetail";
import TeamMemberModal from "./product/TeamMemberModal";
import DeleteModal from "./product/DeleteModal";
import SelectedPdfUrl from "./product/SelectedPdfUrl";

const EditProductForm = () => {
	const location = useLocation();
	const { product, productPhotos, productPdfs } = location.state || {};
	const photos = (productPhotos == null ? "uploads\\" : productPhotos.map((photo) => photo.replace("uploads\\", "")));
	const pdfs = (productPdfs == null ? "uploads\\" : productPdfs.map((pdf) => pdf.pdf_url.replace("uploads\\", "")));
	const [currentStep, setCurrentStep] = useState(1);
	const [editedProduct, setEditedProduct] = useState(product);
	const [selectedStage, setSelectedStage] = useState(null);
	const [selectedHost, setSelectedHost] = useState(null);
	const [selectedTools, setSelectedTools] = useState(null);
	const [files, setFiles] = useState([]);
	const [pdfFiles, setPdfFiles] = useState([]);
	const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
	const [isApproved, setIsApproved] = useState(editedProduct.is_approved); // State to track product approval
	const [isApprovedSequence, setIsApprovedSequence] = useState(editedProduct.is_approvedSequence); // State to track product approval
	const [isSubmitting, setIsSubmitting] = useState(false);
	const modalRef = useRef(null);
	const fileInputRef = useRef(null);
	const pdfInputRef = useRef(null);
	const users = useSelector((store) => store.users);
	const userId = localStorage.getItem('userId');
	const [pdfUrl, setPdfUrl] = useState(null);
	const [selectedPdfUrl, setSelectedPdfUrl] = useState(null); // State for selected PDF URL
	const [geneOptions, setGeneOptions] = useState([]);
	const [geneSequence, setGeneSequence] = useState([]);
	const [isGeneTableOpen, setIsGeneTableOpen] = useState(false);
	const [isProteinTableOpen, setIsProteinTableOpen] = useState(false);
	const [proteinGeneOptions, setProteinGeneOptions] = useState([]);
	const [selectedGene, setSelectedGene] = useState(editedProduct.gene);
	const [selectedProteinGene, setSelectedProteinGene] = useState(editedProduct.protein);
	const [currentRowIndex, setCurrentRowIndex] = useState(null);
	const [isOptionsVisible, setOptionsVisible] = useState(false);
	const [isExperimentModalOpen, setIsExperimentModalOpen] = useState(false);
	const [isExperimentDetailModalOpen, setIsExperimentDetailModalOpen] = useState(false);
	const [currentRowId, setCurrentRowId] = useState(null);
	const [currentExperimentName, setCurrentExperimentName] = useState('');
	const [selectedExperiment, setSelectedExperiment] = useState(null);
	const [selectedExperiment_codonOptimization, setSelectedExperiment_codonOptimization] = useState([]);
	const [isGeneLoadOpen, setIsGeneLoadOpen] = useState(false);


	const navigate = useNavigate();
	const containerStyles = {
		width: "300px",
		height: "280px",
		margin: "0 auto",
	};
	const toggleOptions = () => {
		setOptionsVisible(!isOptionsVisible);
	};
	const openPdf = (pdf) => {
		const pdfUrl = `${AppConfig.charakaApiUrl}/pdfs/download/${pdf}`;
		setSelectedPdfUrl(pdfUrl);
	};
	const selectedOpenPdf = (pdf) => {
		const pdfUrl = URL.createObjectURL(pdf);
		setSelectedPdfUrl(pdfUrl);

	};

	const [selectedTeamMembers, setSelectedTeamMembers] = useState(product.teamMembers.map(id =>
		({ value: id, label: users.find(user => user.id === id)?.name })));
	const dispatch = useDispatch();
	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = selectedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
		fileInputRef.current.value = null; // Clear the input value

	};



	const handlePdfFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const allowedFiles = selectedFiles.filter((file) => file.type === "application/pdf");

		if (allowedFiles.length > 10) {
			alert("Maximum 10 PDF files allowed.");
			return;
		}
		setPdfFiles([...pdfFiles, ...allowedFiles]);
		pdfInputRef.current.value = null; // Clear the input value
	};
	const handleDeleteFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};
	const handleDeletePdf = (index) => {
		setPdfFiles((prevPdfFiles) => prevPdfFiles.filter((_, i) => i !== index));
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		const droppedFiles = Array.from(e.dataTransfer.files);

		// Filter out files that are not jpeg, png, or webp
		const allowedFiles = droppedFiles.filter(
			(file) =>
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/webp"
		);

		// Limit the number of files to a maximum of 10
		if (files.length + allowedFiles.length > 10) {
			alert("Maximum 10 files allowed.");
			return;
		}

		setFiles([...files, ...allowedFiles]);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
	};

	const handleBrowseClick = () => {
		fileInputRef.current.click();
	};

	const handlePdfBrowseClick = () => {
		if (pdfInputRef.current) {
			pdfInputRef.current.click();
		}
	};

	const handleSwitchChange = (checked) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			test_product: checked ? 1 : 0,
		}));
	};
	const handleIsApproved = (e) => {
		const { checked } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			is_approved: checked,
		}));
		setIsApproved(checked);
	};

	const handleIsApprovedSequence = (e) => {
		const { checked } = e.target;
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			is_approvedSequence: checked,
		}));
		setIsApprovedSequence(checked);
	};

	const handleToggleTeamMember = (memberId, e) => {
		e.stopPropagation();

		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: prevProduct.teamMembers.includes(memberId)
				? prevProduct.teamMembers.filter((id) => id !== memberId)
				: [...prevProduct.teamMembers, memberId],
		}));
	};
	const handleTeamLeadChange = (selectedOption) => {
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			team_lead: selectedOption.value,
		}));
	};

	const handleTeamMembersChange = (selectedOptions) => {
		setSelectedTeamMembers(selectedOptions);
		setEditedProduct((prevProduct) => ({
			...prevProduct,
			teamMembers: selectedOptions.map((option) => option.value),
		}));
	};
	useEffect(() => {
		const foundStage = stages.find((stage) => stage.value === editedProduct.stage);
		if (foundStage) {
			setSelectedStage(foundStage);
		}
	}, [editedProduct, stages]);

	useEffect(() => {

		try {
			let codonoptimizations = selectedExperiment?.codon_optimizations;
			const parsedCodonOptimization = JSON.parse(codonoptimizations) ?? [];
			setSelectedExperiment_codonOptimization(parsedCodonOptimization);
			setEditedProduct(e => e.codon_optimizations = parsedCodonOptimization);
		} catch (error) {

		}

	}, [selectedExperiment]);

	useEffect(() => {
		if (editedProduct.gene) {
			fetchGeneData(editedProduct.gene);
		} else {
			setGeneOptions([]);
		}

		if (editedProduct.protein) {
			fetchProteinGeneData(editedProduct.protein);
		} else {
			setProteinGeneOptions([]);
		}
	}, []);



	const fetchGeneSequence = async (geneId, index) => {
		try {
			setIsGeneLoadOpen(true);
			// Step 1: Fetch nucleotide_id
			const responseNucleotide = await fetch(`https://pf.charaka.tech/gene?geneId=${geneId}`, {
				method: 'POST',
			});
			const dataNucleotide = await responseNucleotide.json();

			// Ensure the response is successful and contains the necessary data
			if (dataNucleotide.status && dataNucleotide.data) {
				const nucleotideId = dataNucleotide.data.nucleotide_id;

				// Step 2: Fetch FASTA sequence using nucleotide_id
				const responseFasta = await fetch(`https://pf.charaka.tech/fasta?nucleotide_id=${nucleotideId}`, {
					method: 'POST',
				});
				const fastaData = await responseFasta.json(); // Assuming FASTA data is returned as plain text
				if (fastaData.status && fastaData.data) {
					const fastaSequence = fastaData.data.fasta;
					setGeneSequence(fastaSequence);
					setEditedProduct(prevState => {
						const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
							if (idx === index) {
								return { ...exp, gene_sequence: fastaSequence }; // Update the target_gene field for the specific row
							}
							return exp;
						});
						return {
							...prevState,
							product_experiments: updatedExperiments,
						};
					});
				} else {
					console.error("Error: fasta not found in the response.");
					setGeneSequence("No Gene Sequence Found");

				}
			} else {
				console.error("Error: Nucleotide ID not found in the response.");
			}
		} catch (error) {
			console.error("Error fetching gene sequence:", error);
		}finally{
			setIsGeneLoadOpen(false);

		}
	};

	const fetchGeneData = async (gene) => {

		try {
			const response = await fetch(`${AppConfig.pfCharakaApiUrl}/gene?term=${encodeURIComponent(gene)}`);
			const data = await response.json();
			if (data.status && data.data && data.data.rows) {
				const filteredRows = data.data.rows.filter(row =>
					row.data.id.label.trim() !== '' && row.data.description.label.trim() !== ''
				);
				setGeneOptions(filteredRows);

			} else {
				setGeneOptions([]);
			}
		}
		catch (error) {
			console.error('Error fetching gene data:', error);
		}
	};
	const fetchProteinGeneData = async (proteinGene) => {
		if (proteinGene) {
			try {
				const response = await fetch(`${AppConfig.pfCharakaApiUrl}/protein?term=${encodeURIComponent(proteinGene)}`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();


				if (data.status && data.data && data.data.rows) {
					const filteredRows = data.data.rows.filter(row =>
						row.data.id.label.trim() !== '' &&
						row.data.title.label.trim() !== '' &&
						row.data.gi.label.trim() !== '' &&
						row.data.accessionVersion.label.trim() !== ''
					);
					setProteinGeneOptions(filteredRows);

				} else {
					setProteinGeneOptions([]);
				}
			} catch (error) {
				console.error('Failed to fetch data:', error);
				setProteinGeneOptions([]);
			}
		} else {
			setProteinGeneOptions([]);
		}
	};

	const handleStageChange = (selectedOption) => {
		setSelectedStage(selectedOption);
		setEditedProduct({
			...editedProduct,
			stage: selectedOption ? selectedOption.value : '',
		});
	};
	const handleToolChange = (selectedOption) => {
		setSelectedTools(selectedOption);
		setEditedProduct({
			...editedProduct,
			tools: selectedOption ? selectedOption.value : '',
		});
	};

	const handleHostChange = (selectedOption) => {
		setSelectedHost(selectedOption);

	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		const photosFormData = new FormData(); // Separate FormData for photos
		const pdfFormData = new FormData();
		files.forEach((file) => {
			formData.append("photos", file);
			photosFormData.append("photos", file); // Add files to photosFormData
		});
		pdfFiles.forEach((file) => {
			formData.append("pdfs", file);
			pdfFormData.append("pdfs", file); // Add files to photosFormData
		});
		setIsSubmitting(true);
		// Dispatch action to update the product in Redux store
		try {
			// Make an HTTP PUT request to delete the product

			const productResponse = await apiService.put(
				`/product/${product.id}`,
				editedProduct
			);

			// Handle success, e.g., close the modal and update UI
			if (productResponse.status === 200) {
				if (files.length > 0) {
					const uploadResponse = await apiService.post(
						`/photos/upload/${editedProduct.id}`,
						photosFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					// Handle response
					if (uploadResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onProductEdited();
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onProductEdited();
					// onClose();
				}
				if (pdfFiles.length > 0) {
					const uploadPdfResponse = await apiService.post(
						`/pdf/upload/${editedProduct.id}`,
						pdfFormData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);

					if (uploadPdfResponse.status === 200) {
						dispatch(updateProduct(editedProduct));
						// onClose();
					} else {
						throw new Error("Failed to upload photos");
					}
				} else {
					dispatch(updateProduct(editedProduct));
					// onClose();
				}
				// dispatch(toastNotifySucess("Product updated successfully!"));
				toast.success("Product updated successfully!")
				navigate("/productpage");
			} else {
				throw new Error("Failed to update product");
			}
		} catch (error) {
			// Handle error
			console.error("Error updating product:", error);
			toast.error("An error occurred while updating the product.")
			// dispatch(toastNotifyError("An error occurred while updating the product.", error.response?.data?.errors || []));
		} finally {
			// Set isSubmitting to false after submission is completed (success or failure)
			setIsSubmitting(false);
		}
	};

	const handleOpenDeleteModal = () => {
		setShowDeleteModal(true);
	};

	// Function to handle closing the delete confirmation modal
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	// Function to handle deleting the product
	const handleDeleteProduct = async () => {
		try {
			// Make an HTTP DELETE request to delete the product

			await apiService.delete(`/product/delete/${product.id}`);

			// Handle success, e.g., close the modal and update UI
			dispatch(deleteProduct(product.id));
			toast.success('Product deleted successfully');
			// onClose();
			navigate('/productPage');
		} catch (error) {
			// Handle error
			toast.error('failed to delete the product');
			console.error("Error deleting product:", error);
		}
	};
	const onDeletePdf = (index) => {
		const updatedPdfFiles = [...pdfFiles];
		updatedPdfFiles.splice(index, 1);
		setPdfFiles(updatedPdfFiles);
	};
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const handlePreviousStep = () => {
		setCurrentStep(currentStep - 1);
	};
	const handleSearch = (id) => {
		const index = editedProduct.product_experiments.findIndex((value) => value.id === id);

		const gene = editedProduct.product_experiments[index].gene;
		if (gene) {
			fetchGeneData(gene);
			//fetchGeneSequence(gene);
		}
		setIsGeneTableOpen(true);
		setCurrentRowIndex(index);
	};
	const handleSearchProtein = (id) => {
		const index = editedProduct.product_experiments.findIndex((value) => value.id === id);
		const proteinGene = editedProduct.product_experiments[index].protein;
		if (proteinGene) {
			fetchProteinGeneData(proteinGene);
		}
		setIsProteinTableOpen(true);
		setCurrentRowIndex(index);
	};
	// const handleSelectedGeneChange = (geneId) => {
	// 	setSelectedGene(geneId);
	// 	setEditedProduct(prevState => ({
	// 		...prevState,
	// 		gene: geneId
	// 	}));
	// 	console.log("Selected Gene ID in Parent:", geneId);
	// };
	const handleSelectedGeneChange = (geneId, index) => {
		setSelectedGene(geneId);
		fetchGeneSequence(geneId, index);
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, gene: geneId }; // Update the target_gene field for the specific row
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		console.log("Selected Gene ID in Parent:", geneId);
	};
	const handleSelectedProteinGeneChange = (proteinId, index) => {
		setEditedProduct(prevState => {
			const updatedExperiments = prevState.product_experiments.map((exp, idx) => {
				if (idx === index) {
					return { ...exp, protein: proteinId };
				}
				return exp;
			});
			return {
				...prevState,
				product_experiments: updatedExperiments,
			};
		});
		console.log("Selected Protein Gene ID in Parent:", proteinId);
	};
	const handleDeleteExperimentRow = (index) => {
		console.log(index, "index");

		// Mark the row as deleted
		const updatedExperiments = editedProduct.product_experiments.map((exp, idx) => {
			if (idx === index) {
				return { ...exp, is_deleted: true }; // Soft delete the row
			}
			return exp;
		});

		// Update the state with filtered experiments (hide deleted ones)
		const visibleExperiments = updatedExperiments.filter(exp => !exp.is_deleted);

		setEditedProduct({ ...editedProduct, product_experiments: visibleExperiments });
	};

	const handleRemoveCodonOptimization = (experimentId, codonRowId) => {
		setEditedProduct((prevProduct) => {
			const updatedExperiments = prevProduct.product_experiments.map((exp) => {
				if (exp.id === experimentId) {
					// Filter out the codon optimization to remove it by its unique id (not index)
					const updatedCodonOptimizations = exp?.codon_optimizations.filter((codonRow) => codonRow.id !== codonRowId);
					return {
						...exp,
						codon_optimizations: updatedCodonOptimizations, // Update with the new array
					};
				}
				return exp; // Return the experiment unchanged if it doesn't match
			});

			return {
				...prevProduct,
				product_experiments: updatedExperiments, // Update the state with the modified experiments
			};
		});
	};


	const handleAddRow = () => {
		setEditedProduct(prevProduct => {
			console.log("Current product_experiments:", prevProduct.product_experiments);

			return {
				...prevProduct,
				product_experiments: [
					...(prevProduct.product_experiments || []),
					{
						id: nanoid(),
						experiment_name: '',
						common_name: '',
						scientific_name: '',
						gene: '',
						protein: '',
						is_deleted: false,
					},
				],
			};
		});
	};
	const handleAddRowCodon = (currentRowId) => {
		setEditedProduct((prevProduct) => {
			const updatedExperiments = prevProduct.product_experiments.map((exp) => {
				if (exp.id === currentRowId) {
					return {
						...exp,
						codon_optimizations: [
							...(exp?.codon_optimizations ?? []), // Ensure there's an array
							{
								id: nanoid(), // Generate a unique ID for the new codon optimization
								tool: "", // Default value for tool
								caiScore: "", // Default value for CAI Score
								gcPercentage: "", // Default value for GC Percentage
								optimizedSequence: "", // Default value for Optimized Sequence
								selectedSequence: false, // Default value for Selected Sequence (checkbox)
							},
						],
					};
				}
				return exp;
			});

			return {
				...prevProduct,
				product_experiments: updatedExperiments,
			};
		});
	};



	const handleBack = () => {
		navigate(-1); // Go back to the previous page
	};
	const handleCloseGeneTableModal = () => {
		
			setIsGeneTableOpen(false);
		
	}
	const handleCloseProtienTableModal = () => {
		setIsProteinTableOpen(false);
	}
	const handleCodonOptimization = (experiment, experimentName) => {
		setSelectedExperiment(experiment);
		console.log("handle codon optimization", experiment);

		setCurrentRowId(experiment.id);
		setCurrentExperimentName(experimentName);
		setIsExperimentModalOpen(true);

	};

	const handleExperimentDetails = (experiment, experimentName) => {
		setSelectedExperiment(experiment);
		//setCurrentRowId(experiment.id);
		setCurrentExperimentName(experimentName);
		setIsExperimentDetailModalOpen(true);
	};

	const handleCloseExperimentDetailModal = () => {
		setIsExperimentDetailModalOpen(false);
		//setCurrentRowId(null);
		setCurrentExperimentName('');

	};
	const handleCloseModal = () => {
		setIsExperimentModalOpen(false);
		setCurrentRowId(null);
		setCurrentExperimentName('');

	};
	const handleSaveModal = () => {
		// Logic to save the data
		console.log({
			rowId: currentRowId,
			experimentName: currentExperimentName,

		});
		handleCloseModal();
	};
	const handleSaveDetailModal = () => {
		// Logic to save the data
		console.log({
			//rowId: currentRowId,
			experimentName: currentExperimentName,

		});
		handleCloseExperimentDetailModal();
	};



	return (
		<>
			<div className="main-container mx-auto p-8 max-w-7xl">

				<div className="flex items-center justify-between mb-4">
					<button
						type="button"
						onClick={handleBack}
						className="border-gray-400 border text-gray-600 px-2 py-1 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center text-sm"
						style={{ width: 'fit-content' }}
					>
						<ImageLoader name="left-arrow-active.svg" className="mr-1 h-4 w-3" />
						<span className="whitespace-nowrap">Back to products</span>
					</button>

					<div className="relative">
						<button
							type="button"
							onClick={toggleOptions}
							className="text-gray-600 hover:text-gray-800 flex items-center justify-center"
						>
							<BiDotsVerticalRounded size={20} />
						</button>

						{isOptionsVisible && (
							<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-50">
								<button
									type="button"
									onClick={handleOpenDeleteModal}
									className="w-full px-4 py-2 text-left text-gray-600 hover:text-red-600 hover:bg-gray-100 flex items-center"
								>
									<PiTrash className="mr-2 h-4 w-4" />
									<span>Delete Product</span>
								</button>
							</div>
						)}
					</div>
				</div>
				<h2 className="text-2xl font-semibold mb-4">{editedProduct.name} - Edit</h2>

				<div className="flex flex-col min-h-screen">
					{/* <div className="w-full flex justify-end"> */}
					{/* <button className="hover:text-red-600" onClick={() => onClose()}>
						<IoCloseOutline size={25} />
					</button> */}
					{/* </div> */}

					<form
						className="flex flex-col flex-grow"
						onSubmit={handleSubmit}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
						onDragEnter={handleDragEnter}
						onDragLeave={handleDragLeave}

					>
						{/* Form inputs */}
						<div className={`${currentStep !== 2 ? 'flex-grow' : ''}`}>
							{currentStep === 1 && (
								<ProductInfo
									flag={1}
									editedProduct={editedProduct}
									setEditedProduct={setEditedProduct}
									selectedStage={selectedStage}
									handleStageChange={handleStageChange}
									//stages={/* stage options */}
									users={users}
									handleTeamLeadChange={handleTeamLeadChange}
									selectedTeamMembers={selectedTeamMembers}
									handleTeamMembersChange={handleTeamMembersChange}
									handleSwitchChange={handleSwitchChange}
									handleBrowseClick={handleBrowseClick}
									fileInputRef={fileInputRef}
									handleFileChange={handleFileChange}
									productPhotos={productPhotos}
									files={files}
									containerStyles={containerStyles}
									handleDeleteFile={handleDeleteFile}
								/>
							)}

							{/* Right column */}
							{currentStep === 2 && (
								<ProductHost
									flag={1}
									selectedHost={selectedHost}
									handleHostChange={handleHostChange}
									hosts={hosts}
									userId={userId}
									editedProduct={editedProduct}
									isApproved={isApproved}
									handleIsApproved={handleIsApproved}
									handlePdfBrowseClick={handlePdfBrowseClick}
									pdfInputRef={pdfInputRef}
									handlePdfFileChange={handlePdfFileChange}
									pdfFiles={pdfFiles}
									openPdf={openPdf}
									selectedOpenPdf={selectedOpenPdf}
									handleDeletePdf={handleDeletePdf}
								/>
							)}
							{currentStep === 3 && (
								<ProductExperiments
									flag={1}
									editedProductEdit={editedProduct}
									setEditedProductEdit={setEditedProduct}
									handleSearchEdit={handleSearch}
									handleSearchProteinEdit={handleSearchProtein}
									currentRowIndexEdit={currentRowIndex}
									stagesEdit={stages}
									handleAddRowEdit={handleAddRow}
									handleExperimentDetailsEdit={handleExperimentDetails}
									handleCodonOptimizationEdit={handleCodonOptimization}
									handleDeleteExperimentRowEdit={handleDeleteExperimentRow}
									geneOptionsEdit={geneOptions}
									selectedGeneEdit={selectedGene}
									handleSelectedGeneChangeEdit={handleSelectedGeneChange}
									handleCloseGeneTableModalEdit={handleCloseGeneTableModal}
									isGeneTableOpenEdit={isGeneTableOpen}
									proteinGeneOptionsEdit={proteinGeneOptions}
									selectedProteinGeneEdit={selectedProteinGene}
									handleSelectedProteinGeneChangeEdit={handleSelectedProteinGeneChange}
									handleCloseProtienTableModalEdit={handleCloseProtienTableModal}
									isProteinTableOpenEdit={isProteinTableOpen}
									isGeneLoadOpenEdit={isGeneLoadOpen}
								/>
							)}
						</div>
						{/* Buttons Section at the Bottom */}
						<div className="sticky bottom-0 left-0 right-0 bg-white p-4 flex justify-end items-center">
							{currentStep > 1 && (
								<button
									type="button"
									onClick={handlePreviousStep}
									className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center mr-4"
								>
									<ImageLoader name="left-arrow-active.svg" className="mr-2 h-6 w-4" />
									Previous
								</button>
							)}

							{currentStep < 3 && (
								<button
									type="button"
									onClick={handleNextStep}
									className={`border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-blue-600 hover:border-blue-600 rounded-full flex items-center ${currentStep > 1 ? 'mr-4' : ''}`}
								>
									<ImageLoader name="right-arrow-active.svg" className="mr-2 h-6 w-4" />
									Next
								</button>
							)}

							{currentStep === 3 && (
								<>
									{/* <button
										type="button"
										onClick={handleOpenDeleteModal}
										className="border-gray-400 border-2 text-gray-600 px-3 py-2 hover:text-red-600 hover:border-red-600 rounded-full flex items-center mr-4"
									>
										<PiTrash className="h-6 w-4" />
									</button> */}

									<button
										type="submit"
										className="text-white px-4 py-2 rounded flex items-center"
										disabled={isSubmitting}
										style={{ background: '#7FB541', borderColor: 'transparent' }}
									>
										{isSubmitting ? "Submitting..." : "Submit"}
									</button>
								</>
							)}
						</div>


					</form>
				</div>
				{/* Team member selection modal */}
				{showTeamMemberModal && (
					<TeamMemberModal
						users={users}
						editedProduct={editedProduct}
						handleToggleTeamMember={handleToggleTeamMember}
						setShowTeamMemberModal={setShowTeamMemberModal}
					/>
				)}
				{/* Delete confirmation modal */}
				{showDeleteModal && (
					<DeleteModal
						handleCloseDeleteModal={handleCloseDeleteModal}
						handleDeleteProduct={handleDeleteProduct}
					/>
				)}
				{selectedPdfUrl && (
					<SelectedPdfUrl
						selectedPdfUrl={selectedPdfUrl}
						setSelectedPdfUrl={setSelectedPdfUrl}
					/>
				)}

				{isExperimentModalOpen && selectedExperiment && (
					<CodonOptimization
						flag={1}

						selectedExperimentEdit={selectedExperiment}
						editedProductEdit={editedProduct}
						userIdEdit={userId}
						toolsEdit={tools}
						handleAddRowCodonEdit={handleAddRowCodon}
						handleRemoveCodonOptimizationEdit={handleRemoveCodonOptimization}
						setEditedProductEdit={setEditedProduct}
						handleCloseModalEdit={handleCloseModal}
						handleSaveModalEdit={handleSaveModal}
					/>
				)}


				{isExperimentDetailModalOpen && selectedExperiment && (
					<ExperimentDetail
						flag={1}
						editedProduct={editedProduct}
						selectedExperiment={selectedExperiment}
						setEditedProduct={setEditedProduct}
						setSelectedExperiment={setSelectedExperiment}
						handleCloseExperimentDetailModal={handleCloseExperimentDetailModal}
						handleSaveDetailModal={handleSaveDetailModal}
					/>
				)}

			</div >
			<Footer></Footer>
		</>
	);
};

export default EditProductForm;