import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../hooks';
import { removeNotification } from '../slices/toast';

const ToastNotification = () => {
  const toastState = useAppSelector((state) => state.toast.toastState);
  const dispatch = useAppDispatch();

  useEffect(() => {    
    if (toastState.toastType === 'success') {
      toast.success(toastState.content, {
        onClose: () => dispatch(removeNotification()),
      });
    }
    if (toastState.toastType === 'error') {
      if (toastState.errors && toastState.errors.length > 0) {
        toastState.errors.forEach((errorMsg, key) => {
          toast.error(errorMsg.msg, {
            toastId: errorMsg.param + key,
            onClose: () => dispatch(removeNotification()),
          });
        });
      } else {
        toast.error(toastState.content, {
          onClose: () => dispatch(removeNotification()),
        });
      }
    }
  }, [dispatch, toastState]);

  return (
    <div>
      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default ToastNotification;
