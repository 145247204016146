import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { logout } from '../slices/auth';
import ImageLoader from './ImageLoader';
import { SERVICE_STATUS, IMAGE_CATEGORY } from '../utils/types';
import  '../styles/_header.scss';

const Navbar = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector((state) => state.auth.status);
  const userDetails = useAppSelector((state) => state.auth.userDetails);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header>
      <div className="headerSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <div className="header-left">
                <div className="text-3xl font-bold flex items-center">
                  <div className="logo-wrapper">
                  
                      <ImageLoader name="logo.png" />
                   
                  </div>
                  <span className='poppins'>PF Lab</span>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div className="rightColoum">
                {authStatus === SERVICE_STATUS.SUCCESS && userDetails && (
                  <div className="userInfo">
                    <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
                      <strong>{`${userDetails.firstName} ${userDetails.lastName || ''}`}</strong>
                      <span>
                        <ImageLoader
                          name={userDetails?.avatar || 'no-image.png'}
                          category={IMAGE_CATEGORY.AVATAR64}
                          className="img-fluid cursor-pointer ml-2"
                        />
                      </span>
                      <label htmlFor="arrow">
                        <ImageLoader name="arrow.svg" className="img-fluid" />
                      </label>
                    </div>
                    {isDropdownOpen && (
                      <ul className="showMenu">
                        <li>
                          <Link to="/change-password" >
                            <span>
                              <ImageLoader
                                className="imgFirst img-fluid"
                                name="password.png"
                              />
                              <ImageLoader
                                className="imghover img-fluid"
                                name="password-green.png"
                              />
                            </span>
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link to="/login" onClick={handleLogout}>
                            <span>
                              <ImageLoader className="imgFirst img-fluid" name="Logout.svg" />
                              <ImageLoader className="imghover img-fluid" name="Logout-green.svg" />
                            </span>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
