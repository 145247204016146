import React from 'react';

const TeamMemberModal = ({ 
    users, 
    editedProduct, 
    handleToggleTeamMember, 
    setShowTeamMemberModal }) => {
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white p-8 rounded-md max-h-svh overflow-y-auto">
				<h2 className="text-2xl font-semibold mb-4">Select Team Members</h2>
				<div className="grid grid-cols-2 gap-4">
					{users.map((member) => (
						<label key={member.id} className="flex items-center">
							<input
								type="checkbox"
								className="mr-2"
								checked={editedProduct.teamMembers.includes(member.id)}
								onChange={(e) => handleToggleTeamMember(member.id, e)}
							/>
							{member.name}
						</label>
					))}
				</div>
				<div className="mt-4 flex justify-end">
					<button
						className="bg-green-500 text-white px-4 py-2 rounded-full"
						onClick={() => setShowTeamMemberModal(false)}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	);
};

export default TeamMemberModal;
