'use strict';
export const SAVED_MSG = 'Saved';
export const USER_MSG = {
  ENTER_PASSWORD: 'Please enter password',
  ENTER_EMAIL: 'Please enter email',
  INVALID_EMAIL: 'Please enter a valid email',
  VALID_PASSWORD:
    'At least 6 characters, 1 letter, 1 digit & 1 special character(@$.!%*#?&)',
  ENTER_OTP: 'Please enter OTP',
  INVALID_OTP: 'Invalid OTP; 6 digits required',
  PASSWORD_NO_MATCH: 'Password and confirm password does not match',
  ENTER_CONFIRM_PASSWORD: 'Please confirm password',
  PASSWORD_LENGTH: 'Password Must be at least 6 characters long.',
  PASSWORD_NO_SPACE: 'Password should not contain white space.',
  PASSWORD_NUMBER: 'Password must contain at least one number.',
  PASSWORD_SPECIAL_CHAR:
    'Password must contain at least one special characters (@$.!%*#?&)',

};