import React from 'react';
import Select from "react-select";
import { FaFilePdf } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
const ProductHost = ({
    flag,
    selectedHost,
    handleHostChange,
    hostsAdd,
    userId,
    selectedHostAdd,
    handleHostChangeAdd,
    teamLeadAdd,
    userIdAdd,
    isApprovedAdd,
    setIsApprovedAdd,
    handlePdfBrowseClickAdd,
    pdfInputRefAdd,
    handlePdfFileChangeAdd,
    pdfFilesAdd,
    openPdfAdd,
    handleDeletePdfAdd,

    editedProduct,
    isApproved,
    handleIsApproved,
    hosts,
    handlePdfBrowseClick,
    pdfInputRef,
    handlePdfFileChange,
    pdfFiles,
    openPdf,
    selectedOpenPdf,
    handleDeletePdf
}) => {
    return (
        <>
            <div className="col-span-1 grid grid-cols-2 gap-4">
                {/* Left Column */}
                <div className="col-span-1 grid-cols-1 gap-4">
                    {/* Host Organism */}
                    <div className="h-fit">
                        <label htmlFor="stage" className="block text-black-700 font-bold mb-1">
                            Host:
                        </label>
                        <Select
                            id="host"
                            name="host"
                            value={flag === 0 ? selectedHostAdd : selectedHost}
                            onChange={flag === 0 ? handleHostChangeAdd : handleHostChange}
                            options={flag === 0 ? hostsAdd.map((option) => ({
                                value: option.value,
                                label: option.label,
                            })) :
                                hosts.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                }))}
                        />
                        <br></br>
                    </div>

                    {/* Approve Product */}

                        {flag === 0 ? (
                            teamLeadAdd?.value == userIdAdd && (
                                <div className="mt-3">
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            id="isApproved"
                                            name="isApproved"
                                            checked={isApprovedAdd}
                                            onChange={(e) => setIsApprovedAdd(e.target.checked)}
                                        />
                                        <label htmlFor="isApproved" className="block text-gray-700 font-bold mb-2">
                                            Approve Product:
                                        </label>
                                    </div>
                                </div>
                            )
                        ) : (
                            userId == editedProduct.team_lead && (
                                <div className="mt-3">
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            id="approval"
                                            checked={isApproved}
                                            onChange={handleIsApproved}
                                        />
                                        <label htmlFor="approval" className="block text-black-700 font-bold mb-2">
                                            Approve Product:
                                        </label>
                                    </div>
                                </div>
                            )
                        )}
                  
                    <br></br>

                </div>

                {/* Right Column */}
                <div className="col-span-1 grid grid-cols-1 gap-4">

                    {/* Upload PDF */}
                    <div>
                        <label htmlFor="attachPdf" className="block font-bold mb-1 mr-4">
                            Attach PDF:
                        </label>
                        <button
                            type="button"
                            onClick={flag === 0 ? handlePdfBrowseClickAdd : handlePdfBrowseClick}
                            className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                        >
                            Upload PDFs
                        </button>
                        <input
                            type="file"
                            id="pdfUpload"
                            name="pdfUpload"
                            ref={flag === 0 ? pdfInputRefAdd : pdfInputRef}
                            onChange={flag === 0 ? handlePdfFileChangeAdd : handlePdfFileChange}
                            className="hidden"
                            accept="application/pdf"
                            multiple
                        />
                    </div>

                    <div>
                        {flag === 0 ? (
                            <div className="flex flex-wrap gap-2 mt-1">
                                {pdfFilesAdd.map((pdf, index) => (
                                    <div key={index} className="flex items-center mb-1">
                                        <FaFilePdf className="text-red-500 mr-1" />
                                        <span className="underline cursor-pointer" onClick={() => openPdfAdd(pdf)}>
                                            {pdf.name}
                                        </span>
                                        <button
                                            type="button"
                                            className="bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                            onClick={() => handleDeletePdfAdd(index)}
                                        >
                                            <IoCloseOutline />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="my-3 flex flex-wrap">
                                {editedProduct.pdfs &&
                                    editedProduct.pdfs.map((pdf, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center mb-2 cursor-pointer"
                                            onClick={() => openPdf(pdf.pdf_id)}
                                        >
                                            <FaFilePdf size={24} className="text-red-600 mr-2" />
                                            <span className="text-black">{pdf.pdf_url}</span>
                                        </div>
                                    ))}
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {pdfFiles.length > 0 &&
                                        pdfFiles.map((pdf, index) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <FaFilePdf className="text-red-500 mr-2" />
                                                <span className="underline cursor-pointer" onClick={() => selectedOpenPdf(pdf)}>
                                                    {pdf.name}
                                                </span>
                                                <button
                                                    type="button"
                                                    className="bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                                    onClick={() => handleDeletePdf(index)}
                                                >
                                                    <IoCloseOutline />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>



                </div>
            </div>
        </>
    );
};

export default ProductHost;