import React from 'react';
import  PDFViewer  from "../PDFViewer"; 

const SelectedPdfUrl = ({ 
    selectedPdfUrl,
     setSelectedPdfUrl }) => {
	return (
		<div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
			<div className="bg-white p-4 rounded shadow-lg w-3/4 h-3/4">
				<div className="flex justify-end">
					<button
						onClick={() => setSelectedPdfUrl(null)}
						className="text-black-600"
					>
						Close
					</button>
				</div>

				<PDFViewer
					fileUrl={selectedPdfUrl}
				/>
			</div>
		</div>
	);
};

export default SelectedPdfUrl;
