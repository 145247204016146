import React from 'react';

const DeleteModal = ({ 
    handleCloseDeleteModal, 
    handleDeleteProduct }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-md max-h-svh overflow-y-auto">
                <h2 className="text-2xl font-semibold mb-4">Confirm Deletion</h2>
                <p className="mb-4">
                    Are you sure you want to delete this product?
                </p>
                <div className="flex justify-end">
                    <button
                        onClick={handleCloseDeleteModal}
                        className="mr-4 bg-gray-400 text-white px-4 py-2 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteProduct}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
