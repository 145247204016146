import { IoCloseOutline } from "react-icons/io5";
import Switch from "react-switch";
import Select from "react-select";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ImageSlider from "../ImageSlider";
import AppConfig from "../../AppConfig";
import stages from "../../assets/data/stage";
const ProductInfo = ({
    flag,
    productNameAdd, 
    setProductNameAdd,
    objectiveAdd,
    setObjectiveAdd,
    teamLeadAdd ,
    handleChangeAdd,
    isTestProductAdd,
    getContainerStylesAdd,
    imagesListAdd,
    stageAdd, 
    setStageAdd,
    usersAdd,
    filesAdd,
    handleStageChangeAdd,
    selectedTeamMembersAdd,
    handleTeamMembersChangeAdd,
    handleSwitchChangeAdd,
    handleBrowseClickAdd,
    fileInputRefAdd,
    handleFileChangeAdd,
    handleDeleteFileAdd,



    editedProduct,
    setEditedProduct,
    selectedStage,
    handleStageChange,
    users,
    handleTeamLeadChange,
    selectedTeamMembers,
    handleTeamMembersChange,
    handleSwitchChange,
    handleBrowseClick,
    fileInputRef,
    handleFileChange,
    productPhotos,
    files,
    containerStyles,
    handleDeleteFile,
}) => {


    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-content-center auto-rows-min">
                {/* Left column */}
                <div className="lg:col-span-1 grid grid-cols-2 gap-x-4 gap-y-1 auto-rows-min">
                    {/* Product Name */}
                    <div className="input-container h-fit col-span-2">
                        <label htmlFor="name" className="block font-bold mb-1">
                            Product Name:
                        </label>
                        <input
                            required
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter product name"
                            value={flag === 0 ? productNameAdd : editedProduct.name}
                            onChange={(e) => flag == 0 ? setProductNameAdd(e.target.value) : setEditedProduct({ ...editedProduct, name: e.target.value })}
                            className="border border-gray-300 p-2 rounded w-full"
                        />
                    </div>
                    {/* Stage */}
                    <div className="col-span-2">
                        <label htmlFor="stage" className="block text-black-700 font-bold mb-1">
                            Stage:
                        </label>
                        <Select
                            id="stage"
                            name="stage"
                            value={flag === 0 ? stages.find((stageOption) => stageOption.value === stageAdd) : selectedStage}
                            onChange={flag === 0 ? handleStageChangeAdd : handleStageChange}
                            options={flag === 0 ? stages : stages.map((option) => ({
                                value: option.value,
                                label: option.label,
                            }))}
                            required
                        />
                    </div>
                    {/* Objective */}
                    <div className="textarea-container col-span-2 mb-2 lg:mb-0">
                        <label htmlFor="objective" className="block text-gray-700 font-bold mb-1">
                            Objective:
                        </label>
                        <textarea
                            required
                            placeholder="Enter objective"
                            id="objective"
                            name="objective"
                            value={flag === 0 ? objectiveAdd : editedProduct.objective}
                            onChange={(e) => flag === 0 ? setObjectiveAdd(e.target.value) : setEditedProduct({ ...editedProduct, objective: e.target.value })}
                            className="border border-gray-300 p-2 rounded w-full"
                            rows={4}
                        />
                    </div>
                    {/* Team Lead */}
                    <div className="flex flex-col col-span-2 mb-4 lg:mb-0">
                        <div className="mb-4">
                            <label htmlFor="teamLead" className="block text-black-700 font-bold mb-1">
                                Team Lead:
                            </label>
                            <Select
                                id="teamLead"
                                name="teamLead"
                                value={flag === 0 ? teamLeadAdd : (users.find(user => user.id === editedProduct.team_lead) ? { value: editedProduct.team_lead, label: users.find(user => user.id === editedProduct.team_lead).name } : null)}
                                onChange={flag === 0 ? handleChangeAdd : handleTeamLeadChange}
                                options={flag === 0 ? usersAdd.map((user) => ({
                                    value: user.id,
                                    label: user.name
                                })) : users.map((user) => ({
                                    value: user.id,
                                    label: user.name,
                                }))}
                            />
                        </div>
                        <div>
                            <label htmlFor="team_members" className="block text-black-700 font-bold mb-1">
                                Product team members
                            </label>
                            <Select
                                id="product_team_members"
                                name="product_team_members"
                                value={flag === 0 ? selectedTeamMembersAdd : selectedTeamMembers}
                                onChange={flag === 0 ? handleTeamMembersChangeAdd : handleTeamMembersChange}
                                options={flag === 0 ? usersAdd.map((user) => ({
                                    value: user.id,
                                    label: user.name,
                                }))   : users.map((user) => ({
                                    value: user.id,
                                    label: user.name,
                                }))}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-span-1 mb-4 flex flex-col">
                        <label htmlFor="isTestProduct" className="block text-black-700 font-bold mb-1 mr-4">
                            Is Test Product:
                        </label>
                        <Switch
                            checked={flag === 0 ? isTestProductAdd === 1 : editedProduct.test_product === 1}
                            onChange={flag === 0 ? handleSwitchChangeAdd : handleSwitchChange}
                            onColor="#7FB541"
                            onHandleColor="#699F40"
                            handleDiameter={25}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={26}
                            width={48}
                            className="react-switch"
                        />
                    </div>
                </div>

                {/* Right column */}
                <div className="lg:col-span-1 flex flex-col gap-y-4">
                    {/* Attach Photo */}
                    <div>
                        <label htmlFor="attachPhoto" className="block font-bold mb-1 mr-4">
                            Attach Photo:
                        </label>
                        <button
                            type="button"
                            onClick={flag === 0 ? handleBrowseClickAdd : handleBrowseClick}
                            className="border-dashed border-2 h-10 border-gray-400 text-gray-400 px-4 py-2 rounded w-full"
                        >
                            Upload Photos
                        </button>
                        <input
                            type="file"
                            ref={flag === 0 ? fileInputRefAdd : fileInputRef}
                            accept=".jpg,.png,.webp"
                            multiple
                            onChange={flag === 0 ? handleFileChangeAdd : handleFileChange}
                            className="hidden"
                        />
                    </div>

                    {/* Display Selected Files (Photos) */}

                    <div>
                        {flag === 0 ? (
                            <div className="my-3 flex flex-wrap">
                                {filesAdd.length === 0 && (
                                    <p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
                                        Up to 10 files are allowed to be uploaded with the maximum file size
                                        of 20MB per file. Extensions allowed are .jpeg, .png, and .webp.
                                    </p>
                                )}
                                <div style={getContainerStylesAdd()}>
                                    <ImageSlider imagesListAdd={imagesListAdd} />
                                </div>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {filesAdd.map((file, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className="w-20 h-20 object-cover"
                                            />
                                            <button
                                                type="button"
                                                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                                onClick={() => handleDeleteFileAdd(index)}
                                            >
                                                <IoCloseOutline />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {(!editedProduct.files || editedProduct.files.length === 0) && (
                                    <p className="text-green-700 text-xs" style={{ color: "#7FB541" }}>
                                        Up to 10 files are allowed to be uploaded with the maximum file size
                                        of 20MB per file. Extensions allowed are .jpeg, .png, and .webp.
                                    </p>
                                )}
                                {(productPhotos.length > 0 || files.length > 0) && (
                                    <div
                                        style={{
                                            ...containerStyles,
                                            height: productPhotos.length > 0 || files.length > 0 ? "auto" : 0,
                                        }}
                                    >
                                        <ImageSlider
                                            imagesList={[
                                                ...productPhotos.map((photo) => ({
                                                    url: `${AppConfig.charakaApiUrl}/${photo}`,
                                                    id: photo.photo_id,
                                                })),
                                                ...files.map((file) => ({
                                                    url: URL.createObjectURL(file),
                                                    id: file.name,
                                                })),
                                            ]}
                                        />
                                    </div>
                                )}
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {files.length > 0 &&
                                        files.map((photo, index) => (
                                            <div key={index} className="relative">
                                                <img
                                                    src={URL.createObjectURL(photo)}
                                                    alt={photo.name}
                                                    className="w-20 h-20 object-cover"
                                                />
                                                <button
                                                    type="button"
                                                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-700"
                                                    onClick={() => handleDeleteFile(index)}
                                                >
                                                    <IoCloseOutline />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>

                </div>



            </div>

        </>
    );
};

export default ProductInfo;